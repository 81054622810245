import React, { Component } from 'react'
import pt from 'prop-types'
import qs from 'query-string'
import get from 'lodash/get'
import Widget from '@/components/widgets/Widget'
import CustomApplicationWidget from '@/components/widgets/CustomApplicationWidget'
import WidgetSideBarContainer from '@/containers/regions/WidgetSideBarContainer'
import AnalyticsAlarmsWidget from '@/components/widgets/AnalyticsAlarmsWidget'
import TyumenOurHomeWidget from '@/components/widgets/TyumenOurHomeWidget'
import ServesDescWidget from '@/components/widgets/ServesDescWidget'
import WidgetsDashboardIcon from '@/components/icons/widgetsDashboard'
import SettingsDashboard from './components/SettingsDashboard'
import {
  ANALYTICS_ALARMS,
  TYUMEN_OUR_HOME,
  SUPPORT,
  CITY_PORTAL,
} from '@/constants/widgetsShop/names'
import { ID_BY_APPLICATIONS } from '@/constants/widgets'
import { isUserHasPermission, isSuperAdmin } from '@/helpers/userPermissions'
import WIDGETSHOP_APP_ITEMS from '@/constants/widgetShopApp'
import SwiperDasboard from '@/components/blocks/SwiperDasboard'
import NotifacationsDasboardMobile from '@/components/blocks/NotificationsDasboarMobile'
import {
  WidgetsWrapper,
  StaticWidgetsContainer,
  StaticWidgetsWrapper,
  CustomWidgetsContainer,
  CustomWidgetWrapper,
  HeaderPage,
  Title,
} from './styles'
import data from './config'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import DraggableItem from 'components/blocks/DraggableItem'
import useGetIsMobile from '@/hooks/useGetIsMobile'
import { FormattedMessage as Lang, injectIntl } from 'react-intl'
import useHtmlTitle from '@/hooks/useHtmlTitle'


const withHooks = (Component) => {
  return function(props) {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(MouseSensor),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const isMobile = useGetIsMobile()
    useHtmlTitle(props.intl.messages['menu.desktop'])
    return (
        <Component sensors={sensors} {...props} isMobile={isMobile}/>
    );
  };
}

class Dashboard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openSettingsForId: null,
      selectedWidgetType: null,
      widgetType: null,
      widgetData: null,
      widgetsList: [],
      isSettingsDashboardOpen: false,
      isCityPortal: false,
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.widgets) {
      return {
        widgetsList: nextProps.widgets
      }
    }
    if (qs.parse(window.location.search).closeThisWindow) {
      window.close()
    }
    return null
  }

  componentDidMount() {
    const { requestGetWidgetSettings, requestGetMapStatistic, requestGetTree } = this.props

    requestGetMapStatistic()
    // if(isUserHasPermission(user, ID_BY_APPLICATIONS.ALARM_ANALYTICS)) {
    //   onGetAlarmsCount()
    // }
    requestGetWidgetSettings()
    requestGetTree()
  }

  onOpenSettings = (id, type, widgetType, widgetData, isCityPortal) => {
    const { onToggleAlarmsWidgetSettings, isMobile } = this.props
    if (isMobile) {
      return null
    }
    onToggleAlarmsWidgetSettings(true)
    this.setState({
      openSettingsForId: id,
      selectedWidgetType: type,
      widgetType,
      widgetData,
      isCityPortal: isCityPortal,
    })
  }

  onCloseSettings = () => {
    const { onToggleAlarmsWidgetSettings } = this.props
    this.setState({
      openSettingsForId: null,
    })
    onToggleAlarmsWidgetSettings(false)
  }

  handleDragEnd = (event) => {
    const { active, over } = event
    const { widgetsList } = this.state
    const { onUpdateWidgetsList } = this.props
    if (active.id !== over.id) {
      const oldIndex = widgetsList.map(widget => widget.id).indexOf(active.id)
      const newIndex = widgetsList.map(widget => widget.id).indexOf(over.id)
      const updatedWidgetsList = arrayMove(widgetsList, oldIndex, newIndex)
      this.setState({
        widgetsList: updatedWidgetsList
      })
      onUpdateWidgetsList(updatedWidgetsList)
    }
  }

  onOpenSettingsDashboard = () => {
    this.setState({
      isSettingsDashboardOpen: true
    })
  }

  onCloseSettingsDashboard = () => {
    this.setState({
      isSettingsDashboardOpen: false
    })
  }

  onSwitchStateNotification = () => {
    const { switchStateNotification } = this.props
    switchStateNotification()
  }

  onSwitchStateSwiper = () => {
    const { switchStateSwiper } = this.props
    switchStateSwiper()
  }
  
  renderWidgetSettingsByType = (props) => {
    const {
      onGetAnalyticWidgetData,
      onGetTyumenWidgetData,
      user,
      widgetsData,
      onDeleteWidget,
      requestGetInfoWidget,
      serviceDeskStatus,
      userApplications,
      tree,
      setNode,
      widgets,
      isMobile,
      intl,
      loader
    } = this.props
    switch (props.type) {
      case ANALYTICS_ALARMS: {
        // TODO: change
        if (!isUserHasPermission(user, ID_BY_APPLICATIONS.ALARM_ANALYTICS)) {
          return null
        }
        return (
          <DraggableItem key={props.id} id={props.id} movable={!!widgets.length}>
            <CustomWidgetWrapper>
              <AnalyticsAlarmsWidget
                {...props}
                onOpenSettings={this.onOpenSettings}
                onGetData={onGetAnalyticWidgetData}
                data={widgetsData[props.id]}
                tree={tree}
                setNode={setNode}
                isMobile={isMobile}
                loader={loader}
              />
            </CustomWidgetWrapper>
          </DraggableItem>
        )
      }
      case TYUMEN_OUR_HOME: {
        if (!isUserHasPermission(user, ID_BY_APPLICATIONS.TYUMEN_PORTAL)) {
          return null
        }
        return (
          <DraggableItem key={props.id} id={props.id} movable={!!widgets.length}>
           <CustomWidgetWrapper>
             <TyumenOurHomeWidget
               {...props}
               onOpenSettings={this.onOpenSettings}
               onGetData={onGetTyumenWidgetData}
               data={widgetsData[props.id]}
               isMobile={isMobile}
             />
           </CustomWidgetWrapper>
          </DraggableItem>
        )
      }
      case CITY_PORTAL: {
        if (!isUserHasPermission(user, ID_BY_APPLICATIONS.CITY_PORTAL)) {
          return null
        }
        return (
          <DraggableItem key={props.id} id={props.id} movable={!!widgets.length}>
           <CustomWidgetWrapper>
             <TyumenOurHomeWidget
               {...props}
               onOpenSettings={this.onOpenSettings}
               onGetData={onGetTyumenWidgetData}
               data={widgetsData[props.id]}
               isMobile={isMobile}
               isCityPortal={true}
             />
           </CustomWidgetWrapper>
          </DraggableItem>
        )
      }
      case SUPPORT: {
        if (!isUserHasPermission(user, ID_BY_APPLICATIONS.SERVICE_DESK)) {
          return null
        }
        let isValidSettings = true
        const statusIsArray = serviceDeskStatus instanceof Array
        const currentServiceDeskStatus = (statusIsArray ? serviceDeskStatus : []).find(
          (status) => (
            user.roles.isSuperAdmin
              ? props.id === status.temporaryId
              : props.id === status.widgetId
          ),
        )
        if (!isSuperAdmin(user.role)) {
          const sdConfig = get(user, 'permissions.applications', []).find(({ id }) => id === 26) || {}
          const parsedSettings = JSON.parse(sdConfig.settings || '[]') || []
          const isSdHasInSettings = !!parsedSettings
            .find(({ temporaryId }) => (
              currentServiceDeskStatus
                && temporaryId === currentServiceDeskStatus.temporaryId
            ))
          if (currentServiceDeskStatus && !isSdHasInSettings) {
            onDeleteWidget(props.id, 'widget', get(currentServiceDeskStatus, 'id', null))
            return null
          }
          isValidSettings = parsedSettings
            .find(({ id }) => currentServiceDeskStatus && id === currentServiceDeskStatus.id)
        }
        return (
          <DraggableItem key={props.id} id={props.id} movable={!!widgets.length} noGroups={true}>
           <ServesDescWidget
             {...props}
             isSettingSet={isValidSettings ? props.isSettingSet : false}
             onOpenSettings={this.onOpenSettings}
             data={isValidSettings ? widgetsData[props.id] : undefined}
             onGetData={requestGetInfoWidget}
             serviceDeskStatus={isValidSettings ? currentServiceDeskStatus : undefined}
             userApplications={userApplications}
             isMobile={isMobile}
             intl={intl}
           />
          </DraggableItem>
        )
      }
      default: {
        return null
      }
    }
  }

  renderWidgetByType = (application) => {
    const { user} = this.props
    const applicationFromConfig = WIDGETSHOP_APP_ITEMS
      .find((widget) => widget.id === application.type)
    const isUserHasPermissions = isUserHasPermission(user, applicationFromConfig.applicationId)

    if (!isUserHasPermissions) {
      return null
    }

    return (
      <CustomApplicationWidget
        key={application.icon}
        {...applicationFromConfig}
        {...application}
        onOpenSettings={this.onOpenSettings}
      />
    )
  }

  render() {
    const {
      tree, 
      widgetsData, 
      widgets, 
      applications, 
      isWidgetSettingsOpen, 
      user, 
      sensors, 
      isMobile, 
      onUpdateWidgetsList, 
      isOpenNotification, 
      isActiveSwiper, 
      intl, 
      onGetAlarmsCount,
      ...values
    } = this.props
    const {
      openSettingsForId, selectedWidgetType, widgetType, widgetData, widgetsList, isSettingsDashboardOpen, isCityPortal
    } = this.state

    if (isSettingsDashboardOpen) {
      return <SettingsDashboard 
                onCloseSettingsDashboard={this.onCloseSettingsDashboard} 
                widgetsList={widgetsList} 
                onUpdateWidgetsList={onUpdateWidgetsList}
                onSwitchStateNotification={this.onSwitchStateNotification}
                onSwitchStateSwiper={this.onSwitchStateSwiper}
                isOpenNotification={isOpenNotification}
                isActiveSwiper={isActiveSwiper}
                intl={intl}
              />
    } else {
      return (
        <WidgetsWrapper>
          {isMobile && 
          <HeaderPage>
            <Title><Lang id="widgets.widgetsDashboard" /></Title>
            <WidgetsDashboardIcon.DotsIcon  onClick={this.onOpenSettingsDashboard}/>
          </HeaderPage>}
          {(isMobile && isActiveSwiper) && <SwiperDasboard/>}
          {!isMobile &&
            <StaticWidgetsContainer>
              <StaticWidgetsWrapper>
                {data.map((row) => (
                  <>
                    {row.map((widget) => {
                      const isUserHasPermissions = isUserHasPermission(user, widget.applicationId)
                      if (widget.applicationId && !isUserHasPermissions) {
                        return null
                      }
                      const count = (values[widget.countSelector] || []) || widget.count
                      let valueList = null
                      if (widget.valueList && widget.valueList.length > 0) {
                        valueList = widget.valueList.map((element) => ({
                          title: typeof element.title === 'function'
                            ? element.title(values[element.countSelector] || 0)
                            : element.title,
                          value: values[element.countSelector] || 0,
                          highLight: element.highLight || false,
                        }))
                      }
                      let descriptionFields = null
                      if (widget.descriptionFields && widget.descriptionFields.length > 0) {
                        descriptionFields = widget.descriptionFields.map((element) => ({
                          value: values[element.selector] || '',
                          highLight: element.highLight || false,
                        }))
                      }
                      return (
                        <Widget
                          key={widget.name}
                          {...widget}
                          valueList={valueList || []}
                          descriptionFields={descriptionFields || []}
                          count={count}
                          title={widget.getTitle ? widget.getTitle(count) : widget.title}
                        />
                      )
                    })}
                  </>
                ))}
              </StaticWidgetsWrapper>
              <StaticWidgetsWrapper>
              {applications.map(this.renderWidgetByType)}
            </StaticWidgetsWrapper>
          </StaticWidgetsContainer>}
          {(isMobile && isOpenNotification ) && <NotifacationsDasboardMobile onGetAlarmsCount={onGetAlarmsCount} newEventCount={values.newEventCount} onSwitchStateNotification={this.onSwitchStateNotification} user={user}/> }
          <CustomWidgetsContainer>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={this.handleDragEnd}
              autoScroll={false}
            >
              <SortableContext
                items={widgetsList}
                strategy={rectSortingStrategy}
              >
                {isMobile ? widgetsList.filter((widget) => widget.isActive).map(this.renderWidgetSettingsByType) : widgetsList.map(this.renderWidgetSettingsByType)}
              </SortableContext>
            </DndContext>
          </CustomWidgetsContainer>
          <WidgetSideBarContainer
            widgetType={widgetType}
            widgetId={openSettingsForId}
            isOpen={isWidgetSettingsOpen}
            onClose={this.onCloseSettings}
            selectedWidgetType={selectedWidgetType}
            data={widgetData}
            isCityPortal={isCityPortal}
          />
        </WidgetsWrapper>
      )
    }
  }
}

Dashboard.propTypes = {
  widgets: pt.arrayOf(pt.shape({
    id: pt.string,
  })),
  applications: pt.arrayOf(pt.shape({
    id: pt.string,
  })),
  onGetAlarmsCount: pt.func.isRequired,
  onGetAnalyticWidgetData: pt.func.isRequired,
  onGetTyumenWidgetData: pt.func.isRequired,
  widgetsData: pt.shape({}).isRequired,
  onToggleAlarmsWidgetSettings: pt.func.isRequired,
  isWidgetSettingsOpen: pt.bool.isRequired,
  user: pt.shape({
    modules: pt.arrayOf(pt.number),
    roles: pt.shape({
      isSuperAdmin: pt.bool,
    }),
  }).isRequired,
}
Dashboard.defaultProps = {
  widgets: [],
  applications: [],
}

export default withHooks(injectIntl(Dashboard))
