import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import InventoryCell from '@/components/blocks/DutyTable/customCells/InventoryCell'


export const fields = [
  {
    id: 'id',
    name: <Lang id="inventory.table.number" />,
    value: 'ASC',
    customWidth: 90,
    type: 'string',
  },
  {
    id: 'registerNumber',
    name: <Lang id="inventory.table.registerNumber" />,
    value: 'ASC',
    customWidth: 150,
    type: 'string',
  },
  {
    id: 'asuno',
    name: <Lang id="inventory.table.asuno" />,
    value: 'ASC',
    customWidth: 120,
    hideSortButtons: true,
    customCell: (props) => <InventoryCell {...props} diff='lightFixtureDiff'/>,
  },
  {
    id: 'controlCupboardFirstIntegration',
    name: <Lang id="inventory.table.controllerFirstIntegration" />,
    value: 'ASC',
    customWidth: 120,
    customCell: (props) => <InventoryCell {...props} diff='controllerDiff'/>,
  },
  {
    id: 'baseStationFirstIntegration',
    name: <Lang id="inventory.table.baseStationFirstIntegration" />,
    value: 'ASC',
    customWidth: 190,
    customCell: (props) => <InventoryCell {...props} diff='baseStationDiff'/>,
  },
  {
    id: 'lampModuleFirstIntegration',
    name: <Lang id="inventory.table.lampModuleFirstIntegration" />,
    value: 'ASC',
    customWidth: 125,
    customCell: (props) => <InventoryCell {...props} diff='lampModuleDiff'/>,
  },
  {
    id: 'utilityPoleFirstIntegration',
    name: <Lang id="inventory.table.utilityPoleFirstIntegration" />,
    value: 'ASC',
    customWidth: 140,
    customCell: (props) => <InventoryCell {...props} diff='utilityPoleDiff'/>,
  },
  {
    id: 'lightFixtureFirstIntegration',
    name: <Lang id="inventory.table.lightFixtureFirstIntegration" />,
    value: 'ASC',
    customWidth: 180,
    customCell: (props) => <InventoryCell {...props} diff='lightFixtureDiff'/>,
  },
  {
    id: 'cableSewers',
    name: <Lang id="inventory.table.cableSewers" />,
    value: 'ASC',
    customWidth: 180,
    hideSortButtons: true,
    customCell: (props) => <InventoryCell {...props} diff='cableSewersDiff'/>,
  },
  {
    id: 'asunoAhp',
    name: <Lang id="inventory.table.asuno" />,
    value: 'ASC',
    customWidth: 120,
    hideSortButtons: true,
    customCell: (props) => <InventoryCell {...props} />,
  },
  {
    id: 'shunoAhp',
    name: <Lang id="inventory.table.controllerFirstIntegration" />,
    value: 'ASC',
    customWidth: 120,
    hideSortButtons: true,
    customCell: (props) => <InventoryCell {...props} />,
  },
  {
    id: 'lightFixtureAhp',
    name: <Lang id="inventory.table.lightFixtureFirstIntegration" />,
    value: 'ASC',
    customWidth: 180,
    hideSortButtons: true,
    customCell: (props) => <InventoryCell {...props} />,
  },
]