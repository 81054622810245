import getQueryParams from '@/helpers/getQueryParams'
import {
  ROLE_SUPER_ADMIN,
} from '@/constants/role'
import {
  USERS_GROUP,
} from '@/constants/viewTree'

export const API_URL = process.env.REACT_APP_API_URL || 'https://dev.sls24.net'
export const API_PREFIX = process.env.REACT_APP_API_PREFIX || '/api'
export const AUTH_API_PREFIX = process.env.REACT_APP_AUTH_API_PREFIX || '/auth'

export const API = `${API_PREFIX}`
export const AUTH_API = `${AUTH_API_PREFIX}`

// Dictionaries endpoints
export const CITY_PROCESSES_ENPOINT = `${API}/allCityProcesses`
export const FUNCTIONAL_MODULES_ENPOINT = `${API}/allFunctionalModules`
export const GET_CITY_PROCESSES = `${API}/city-processes`
export const GET_ALL_APPLICATIONS_URL = `${API}/applications`
export const GET_ALL_APPLICATIONS_MODELS_URL = `${API}/applications/modules`
export const GET_OBJECT_TYPES_URL = `${API}/v1/dictionary/object-type`
export const GET_EXPLOITATION_ORGANIZATIONS_URL = `${API}/v1/installation/dictionary/object-element/exploitation-organization`
export const GET_OWNERS_URL = `${API}/v1/installation/dictionary/object-element/owner`
export const GET_SEARCH_FIELDS_OPTIONS_URL = (params) => `${API}/v1/installation/dictionary/object-element?${getQueryParams(params)}`
export const GET_CONNECTED_INTEGRATIONS_URL = `${API}/v1/filter/installation-tree?treeNodeType=INTEGRATION&includeAll=true&sort=name%3A%20ASC`
export const GET_OBJECTS_GROUPS_FILTER_OPTIONS_URL = ({params}) => `${API}/v1/group/dictionary?${getQueryParams(params)}`
export const GET_ALERT_FILDS_OPTIONS = ({ params }) => `${API}/v1/alert/dictionary?${getQueryParams(params)}`

// Auth endpoints
export const API_AUTH = `${API}/oauth`
export const AUTH_API_AUTH = `${AUTH_API}/oauth`
export const LOGIN_API_URL = `${AUTH_API_AUTH}/token`
export const LOGOUT_API_URL = `${API_AUTH}/token`
export const RESET_PASSWORD_API_URL = `${API}/password`
export const FORGOT_PASSWORD = `${AUTH_API}/forgot-password`
export const ACTIVITY_PING_URL = `${API}/active-session/ping`

// Users endpoints
export const USER_SETTINGS_ENDPOINT = `${API}/user-settings`
export const USER_WIDGET_SETTINGS_ENDPOINT = `${API}/user-widget-settings`
export const TENANT_ENDPOINT = `${API}/tenants`
export const DELETE_TENANT_GROUP = `${API}/groups`
export const UPDATE_TENANT_GROUP = `${API}/groups`
export const CREAT_USER_URL = `${API}/users`
export const CREATE_TENANT_GROUP_URL = `${API}/groups`
export const GET_TENANT_GROUPS_URL = `${API}/groups`
export const GET_ONLINE_USERS_URL = `${API}/users/online`
export const generateTenantEndpoint = (id) => `${API}/tenants/${id}`
export const generateAllUsers = (groupId) => `${API}/groups/${groupId}/users`
export const generateDeleteGroup = (groupId) => `${API}/groups/${groupId}`
export const generateGetTenantGroup = (groupId) => `${API}/groups/${groupId}`
export const generatePutAdmin = (id) => `${API}/users/${id}`
export const generateManagementData = (userIds) => `${API}/users/${userIds}/management-data`
export const resendVerificationEmailEndpoint = (id) => `${API}/users/email/resend/${id}`

export const generateSortedUsers = ({
  currentRole, entityId, params, type,
}) => {
  if (currentRole === ROLE_SUPER_ADMIN) {
    return `${TENANT_ENDPOINT}/${entityId}/users?${getQueryParams(params)}`
  }
  if (type === USERS_GROUP) {
    return `${API}/groups/${entityId}/users?${getQueryParams(params)}`
  }
  return `${API}/users?${getQueryParams(params)}`
}

export const generateAllAdminTenants = ({ currentRole, tenantId, params }) => (
  currentRole === ROLE_SUPER_ADMIN
    ? `${TENANT_ENDPOINT}/${tenantId}/users?${getQueryParams(params)}`
    : `${API}/users?${getQueryParams(params)}`
)

// Live time reports endpoints
export const REPORT_URL = `${API}/report`
export const GET_LIVE_TIME_REPORT_BAR_DATA_URL = ({ id, params }) => `${API}/historical/${id}/graph-statistic?${getQueryParams(params)}`
export const GET_LIVE_TIME_REPORT_TABLE_URL = (params) => `${API}/historical/life-time/table-state?${getQueryParams(params)}`
export const GET_LIVE_TIME_OBJECT_TYPES_URL = `${API}/historical/dictionary/available-object-types`
export const REPORT_FILE_URL = `${API}/report-file`
export const generateEditGroups = (groupId) => `${API}/groups/${groupId}`
export const generateLiveTimeReportFileUrl = ({ format, params }) => `${API}/v1/report-file/${format}/generator/work-time/download-table-report?${getQueryParams(params)}`

// Resource analytics endpoints
export const RESOURCE_ANALYTICS_ENDPOINT = `${API}/v1/historical/kulon/consumption-analytics`
export const HEAT_ANALYTICS_ENDPOINT = `${API}/v1/report/heat-analytics`
export const RESOURCE_ANALYTICS_TABLE_ENDPOINT = (params) => `${RESOURCE_ANALYTICS_ENDPOINT}/table?${getQueryParams(params)}`
export const RESOURCE_ANALYTICS_GRAPH_ENDPOINT = `${RESOURCE_ANALYTICS_ENDPOINT}/graph`
export const RESOURCE_ANALYTICS_HISTORY_GRAPH_ENDPOINT = (params) => `${RESOURCE_ANALYTICS_GRAPH_ENDPOINT}/history-actual?${getQueryParams(params)}`
export const RESOURCE_ANALYTICS_PLAN_GRAPH_ENDPOINT = (params) => `${RESOURCE_ANALYTICS_GRAPH_ENDPOINT}/plan-actual?${getQueryParams(params)}`
export const RESOURCE_ANALYTICS_AVERAGE_ENDPOINT = (params) => `${RESOURCE_ANALYTICS_ENDPOINT}/average?${getQueryParams(params)}`
export const HEAT_ANALYTICS_TABLE_ENDPOINT = (params) => `${HEAT_ANALYTICS_ENDPOINT}/table?${getQueryParams(params)}`
export const HEAT_ANALYTICS_GRAPH_ENDPOINT = (params) => `${HEAT_ANALYTICS_ENDPOINT}/graph?${getQueryParams(params)}`
export const generateResourceAnalyticsReportFileUrl = (
  {
    fileType = 'pdf',
    params
  },
) => `${API}/v1/report/resource-analytics/download-${fileType}-table-state-report?${getQueryParams(params)}`
export const generateHeatAnalyticsReportFileUrl = (
  {
    fileType = 'pdf',
    params
  },
) => `${API}/v1/report-file/heat-supply/download-table-report/${fileType}?${getQueryParams(params)}`

// Alarm reports
export const ALARMS_URL = `${API}/alarm`
export const ALARM_MANAGER_GRAPH_ENDPOINT = `${ALARMS_URL}/graph`
export const ALARM_MANAGER_TABLE_ENDPOINT = ({ params }) => `${API}/v1/alert?${getQueryParams(params)}`
export const ALARM_MANAGER_AVERAGE_ENDPOINT = ({ id, params }) => `${API}/v1/alert/${id}/statistic-count?${getQueryParams(params)}`
export const ALARM_MANAGER_UNREAD_ALARMS_COUNT_ENDPOINT = `${API}/v1/alert/unread/count`
export const ALARM_MANAGER_SET_READ_ALARM_ENDPOINT = (id) => `${API}/v1/alert/${id}`
export const ALARM_MANAGER_SET_ALL_READ_ALARM_ENDPOINT = `${API}/v1/alert`
export const ALARM_MANAGER_CHECK_ALL_ALARMS = `${ALARMS_URL}/unread-alarm/check`
export const generateAlarmManagerFileUrl = (
  {
    fileType = 'pdf',
  },
) => `${REPORT_FILE_URL}/download-alarm-table-report/${fileType}`

export const ALARM_MANAGER_REPORT_TABLE_FILE_ENDPOINT = ({ format, params }) => `${API}/v1/report-file/${format}/generator/installation-alert?${getQueryParams(params)}`

export const ALARMS_ANALYTICS_ENDPOINT = `${ALARMS_URL}/analytics`
export const ALARMS_ANALYTICS_SUMMARY_TABLE_ENDPOINT = ({ params }) => `${API}/v1/alert/geo-zone/analytic?${getQueryParams(params)}`
export const ALARMS_ANALYTICS_TABLE_ENDPOINT = ({ params }) => `${API}/v1/alert/object/analytic?${getQueryParams(params)}`
export const ALARMS_ANALYTICS_ALARM_ENDPOINT = `${ALARMS_ANALYTICS_ENDPOINT}/object-alarm-history`

export const ALARM_ANALYTICS_REPORT_SUMMARY_TABLE_FILE_ENDPOINT = ({ format, params }) => `${API}/v1/report-file/${format}/generator/geo-zone-alert-analytic?${getQueryParams(params)}`
export const ALARM_ANALYTICS_REPORT_TABLE_FILE_ENDPOINT = ({ format, params }) => `${API}/v1/report-file/${format}/generator/installation-alert-analytic?${getQueryParams(params)}`

export const ANALYTICS_URL = `${API}/analyticsAlarms`

//  Passportization
export const DELETE_PASSPORT_FILES_URL = REPORT_URL
export const GET_PASSPORT_FILES_URL = `${REPORT_URL}/download`
export const UPLOAD_URL = `${REPORT_URL}/upload`
export const ADD_PASSPORT_PHOTO_URL = `${UPLOAD_URL}/photo`
export const ADD_PASSPORT_FILE_URL = `${UPLOAD_URL}/passport`
export const generateGetPassportDataURL = `${API}/passport-card`
export const GET_PASSPORT_DATA_URL = `${API}/passport-system`
export const GET_ELEMENT_EQUIPMENT = `${GET_PASSPORT_DATA_URL}/child-elements`
export const EXPORT_PASSPORT_FILE_URL = ({id, params}) => `${API}/v1/installation/file/${id}/passport?${getQueryParams(params)}`
export const generateGetElementEquipment = (name, type) => `${GET_ELEMENT_EQUIPMENT}?identifier=${name}&installationType=${type}`
export const generatePutPassport = (type) => `${GET_PASSPORT_DATA_URL}?installationType=${type}`
export const GET_STREAM_ENDPOINT = (id) => `${API}/v1/installation/object-stream-video/${id}`
export const SET_STREAM_ENDPOINT = `${API}/v1/installation/object-stream-video`
export const FIELDS_SETTINGS_ENDPOINT = `${API}/installation/passport-field-settings`

// Map equipment
export const EQIPMENT_URL = `${API}/equipment`
export const KULON_EQIPMENT_URL = `${EQIPMENT_URL}/kulon`
export const ATM_EQIPMENT_URL = `${EQIPMENT_URL}/one-sim`
export const MESH_EQIPMENT_URL = `${EQIPMENT_URL}/mesh`
export const BRIZ_EQIPMENT_URL = `${EQIPMENT_URL}/briz`
export const UNILIGHT_EQIPMENT_URL = `${EQIPMENT_URL}/unilight`

// Tyumen our home
export const CITY_PORTAL_URL = `${API}/city-portal-analyzer`
export const TYUMEN_OUR_HOME_URL = `${CITY_PORTAL_URL}/appeal-card`
export const TYUMEN_OUR_HOME_WIDGET_URL = `${CITY_PORTAL_URL}/widget-info`
export const generateTyumenOurHomeUrl = (type) => `${TYUMEN_OUR_HOME_URL}/${type}`
export const generateTyumenOurHomeWidgetUrl = (type) => `${TYUMEN_OUR_HOME_WIDGET_URL}/${type}`
export const TYUMEN_OUR_HOME_GRAPHS_STATUSES_URL = `${API}/city-portal-analyzer-graph/appeal-statuses`
export const TYUMEN_OUR_HOME_GRAPHS_TOPICS_URL = `${API}/city-portal-analyzer-graph/appeal-topics`
export const TYUMEN_OUR_HOME_GRAPHS_STATISTIC_URL = `${API}/city-portal-analyzer-graph/appeal-statistic`
export const TYUMEN_OUR_HOME_GRAPHS_TIME_URL = `${API}/city-portal-analyzer-graph/appeal-avg-resolve-time`
export const generateTyumenOurHomeDateGraphUrl = (appealType) => `${API}/city-portal-analyzer-graph/appeal-earliest-dates/${appealType}`

// refresh elements
export const REFRESH_OBJECT_TELEMETRY = `${API}/v1/installation-object-state`

// service desk
export const SERVICE_DESK_GET_APPEALS_URL = `${API}/service-desk/appeals-list`
export const SERVICE_DESK_INTEGRATION_URL = `${API}/service-desk/integration`

// Integrations
export const INTEGRATIONS_URL = `${API}/integrations`
export const generateIntegrationUrl = (id = '') => `${INTEGRATIONS_URL}/${id}`
export const generateIntegrationsInfoUrl = (ids = []) => `${INTEGRATIONS_URL}/${ids}/info`
export const generateIntegrationTypeUrl = (type = '') => `${INTEGRATIONS_URL}/${type}`
export const generateIntegrationTypeWithIdUrl = (type = '', id = '') => `${generateIntegrationTypeUrl(type)}/${id}`
export const generateRefreshIntegrationUrl = (type = '', id = '') => `${generateIntegrationTypeWithIdUrl(type, id)}/refresh`

// DASHBORD APP

export const UNKNOWN_DASHBOARD_ROUT = `${API_URL}/UNKNOWN_DASHBOARD_ROUT`
export const STATE_OF_OBJECT = `${ALARMS_URL}/analytics/state-of-objects`
export const ALARM_REGISTER = `${ALARMS_URL}/automatic-register-malfunctions`
export const EQUIPMENT_WEAR = `${REPORT_URL}/analytics/deprecation-of-equipment`
export const TUMEN_PORTAL_INFO = `${CITY_PORTAL_URL}/widget-info/dashboard`
export const HEAT_DASHBOARD_GRAPH = `${REPORT_URL}/heat-analytics/graph`
export const LIGHT_DASHBOARD_GRAPH = `${REPORT_URL}/resource-analytics/graph/plan-actual`
export const TUMEN_OUR_HOME_DASHBOARD = `${CITY_PORTAL_URL}/widget-info/dashboard`
export const INFRASTRUCTURE_COUNT_DASHBOARD = ({ params }) => `${API}/v1/installation-tree/count-child/infrastructure?${getQueryParams(params)}`
export const GET_APPLICATIONS_VERSION_URL = `${API}/v1/application`
export const GET_ALL_APPLICATIONS_FILE_URL = `${API}/v1/application/file`
export const GET_APPLICATIONS_FILE_URL = (fileId) => `${API}/v1/application/file/${fileId}`

// INSTALLATION
export const INSTALLATION_URL = `${API}/installation`
export const INSTALLATION_FILE_SCHEME_UPLOAD = (id) => `${API}/v1/installation/file/${id}/upload`
export const INSTALLATION_ERROR_FILE_DOWNLOAD = (id) => `${API}/v1/installation/file/validated-file/${id}`
export const INSTALLATION_FILE_SCHEME_DOWNLOAD = `${API}/v1/installation/file/download`
export const INSTALLATION_ROOT_ELEMENT = `${API}/v1/installation-tree`
export const INSTALLATION_ELEMENT_CHILDREN = ({ params }) => `${API}/api/v1/tree/installation?${getQueryParams(params)}`
export const MAPS_ELEMENT_CHILDREN  = ({ params }) => `${API}/api/v1/tree/telemetry?${getQueryParams(params)}`
export const generateGetRootChildren = (id) => `${INSTALLATION_ROOT_ELEMENT}/${id}/child-elements`
export const generateGetDeleteChildren = (id) => `${INSTALLATION_ROOT_ELEMENT}/${id}`
export const generateDeleteTreeElement = (id) => `${API}/v1/installation-tree/${id}`
export const generateGetTreeElement = (id) => `${API}/v1/installation-tree/${id}`
export const generateGetGeoZoneCard = (id) => `${API}/v1/installation/geo-zone/${id}`
export const generateGetGeoZoneCardStatistic = ({ params, id }) => `${INSTALLATION_ROOT_ELEMENT}/${id}/statistic?${getQueryParams(params)}`
export const generateGetGeoZoneInstalledCardStatistic = (id) => `${API}/v1/telemetry-tree/${id}/statistic`
export const generateGetGeoZoneStatistic = ({id, params}) => `${API}/v1/filter/installation-tree/telemetry/${id}/asu/statistic?${getQueryParams(params)}`
export const generateGetZonesStatistic = ({params}) => `${API}/v1/filter/installation-tree/telemetry/statistic?${getQueryParams(params)}`
export const generateGetProjectCard = (id) => `${API}/v1/installation/projects/${id}`
export const generateDeleteProjectCustomField = (id) => `${API}/v1/installation/projects/custom-field/${id}`
export const INSTALLATIONS_GEOZONE_ENDPOINT = `${API}/v1/installation/geo-zone`
export const INSTALLATIONS_PROJECT_ENDPOINT = `${API}/v1/installation/projects`
export const generateGetDeleteProjectCard = (id) => `${INSTALLATION_URL}/project/${id}`

export const generateGetObjectTelemetry = ({ params, id }) => `${API}/v1/monitoring-center/data/${id}?${getQueryParams(params)}`
export const INSTALLATIONS_INTEGRATION_ENDPOINT = `${API}/v1/installation/integration`
export const getFreeIntegrations = ({params}) => `${API}/v1/installation/integration/available-to-connect?${getQueryParams(params)}`
export const getConnectedIntegrations = (id) => `${API}/v1/installation/integration/connected/${id}`
export const generateGetDeleteIntegrationCard = (id) => `${API}/v1/installation/integration/${id}`
export const generateGetTreeIntegration = (id) => `${INSTALLATION_ROOT_ELEMENT}/integration/tree-element/${id}`
export const generateGetTreeIntegrationElement = (id) => `${API}/v1/installation/integration/${id}`

export const INSTALLATIONS_OBJECT_ENDPOINT = `${API}/v1/installation/object-element`
export const generateGetObjectCard = (id) => `${API}/v1/installation/object-element/${id}`
export const generateGetDeleteObject = (id) => `${INSTALLATION_URL}/object-element/${id}`
export const generateGetTreeObject = (id) => `${INSTALLATION_ROOT_ELEMENT}/object-element/tree-element/${id}`

export const GET_TYPES = `${INSTALLATION_URL}/child-element/available-children-type`
export const generateGetTypes = (integrationType, type) => `${INSTALLATION_URL}/child-element/available-children-type/${integrationType}${type ? `?currentEquipmentType=${type}` : ''}`

export const generateGetTreeElementChildren = (id) => `${INSTALLATION_URL}/installation-tree/${id}/child-elements`
export const generateGetTree = (id) => `${INSTALLATION_URL}/installation-tree/tree/${id}`
export const generateChildCount = (id) => `${INSTALLATION_URL}/installation-tree/count-children/${id}`

export const generateGetTreeWithStatus = (id) => `${INSTALLATION_URL}/installation-tree/filtered-tree/${id}`
export const generateGetParentNode = ({ params }) => `${API}/v1/filter/installation-tree?${getQueryParams(params)}`
export const generateGetParentNodeTelemetry = ({ params }) => `${API}/v1/filter/telemetry-tree?${getQueryParams(params)}`
export const generateGetParentTree = ({ params }) => `${API}/v1/filter/installation-tree/telemetry?${getQueryParams(params)}`
export const getScheme = (id) => `${INSTALLATION_URL}/installation-tree/count-children/${id}`
export const generateDownloadMultipleObject = (id) => `${INSTALLATION_URL}/installation-tree/count-children/${id}`

export const generateGetValidationScheme = (integrationType, type) => `${INSTALLATION_URL}/validation-schema/${integrationType}${type && `?currentEquipmentType=${type}`}`
export const generateValidateObject = (id) => `${API}/v1/installation/object-element/${id}/verification`
export const generateGetObjectConnectedIdentifiers = ({params}) => `${API}/v1/installation-tree/children?${getQueryParams(params)}`
export const generateGetConnectedLinesCoordinates = ({params}) => `${API}/v1/filter/installation-tree/linked?${getQueryParams(params)}`

// INSTALLATION UPLOAD DOWNLOAD GENERATE FILE AND PHOTO
export const generateDownloadGeneratedFile = (id) => `${REPORT_FILE_URL}/download-passport-installation/${id}`
export const INSTALLATION_UPLOAD_FILE = `${REPORT_URL}/upload`
export const generateInstallationDeleteFile = (id, fileId) => `${REPORT_URL}/${id}/${fileId}`
export const generateStartSettingProcessGetFile = (id) => `${REPORT_FILE_URL}/download/order-for-commission-of-the-object/${id}`

// REPORT MANAGER
export const REPORT_MANAGER_URL = `${API}/v1/report-manager`
export const REPORT_MANAGER_TABLE_URL = ({ params }) => `${REPORT_MANAGER_URL}/reports?${getQueryParams(params)}`
export const REPORT_MANAGER_TYPES_URL = `${REPORT_MANAGER_URL}/report-types`
export const REPORT_MANAGER_OBJECT_TYPES_URL = ({ params, type }) => `${REPORT_MANAGER_URL}/report-types/${type}/object-types?${getQueryParams(params)}`
export const REPORT_MANAGER_COMMON_USERS_URL = `${API}/common/users`
export const CREATE_REPORT_URL = `${REPORT_MANAGER_URL}/reports`
export const GET_REPORT_URL = ({ id }) => `${REPORT_MANAGER_URL}/reports/${id}`
export const GET_REPORT_FILE_URL = ({params}) => `${CREATE_REPORT_URL}/table/export?${getQueryParams(params)}`

// asu controller
// /installation/asuno
export const generateGetIntegrationById = (id) => `${INSTALLATION_URL}/asuno?asunoId=${id}`

// search
export const SEARCH_TABLE_ENDPOINT = ({ params }) => `${API}/v1/filter/object-installation?${getQueryParams(params)}`
export const generateSearchReportFileUrl = ({ params }) => `${API}/v1/report-file/xlsx/generator/advanced-object-search?${getQueryParams(params)}`

//PPR
export const PPR_TABLE_ENDPOINT = ({ params }) => `${API}/v1/filter/ppr?${getQueryParams(params)}`
export const PPR_FILE_ENDPOINT = ({ params }) => `${API}/v1/installation/ppr/generate?${getQueryParams(params)}`
export const PPR_INSTALLATION_OBJECT = ({ parentId, params }) => `${API}/v1/ppr/${parentId}/installation-object?${getQueryParams(params)}`

// scheduleManager
export const SCHEDULE_MANAGER_TREE_ENDPOINT = `${API}/v1/schedule-manager/geo-zones/tree`
export const SCHEDULE_MANAGER_CREATE_ENDPOINT = `${API}/v1/schedule-manager/schedules`
export const SCHEDULE_MANAGER_SCHEDULE_ENDPOINT = (id) => `${API}/v1/schedule-manager/schedules/${id}`
export const SCHEDULE_MANAGER_FAILURE_LOG_TABLE_ENDPOINT = ({ params }) => `${API}/v1/schedule-failure-alarm/table-state?${getQueryParams(params)}`
export const SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE_ENDPOINT = ({ params }) => `${API}/v1/user-action-log/table-state?${getQueryParams(params)}`
export const generateScheduleManagerReportFileUrl = ({ format, params }) => `${API}/v1/report-file/${format}/generator?${getQueryParams(params)}`
export const SCHEDULE_FILE_SCHEME_UPLOAD = () => `${API}/v1/file/upload`
export const SCHEDULE_ERROR_FILE_DOWNLOAD = (id) => `${API}/v1/file/validated-file/${id}`

// objectsGroups
export const OBJECTS_GROUPS_TREE_ENDPOINT = ({ params }) => `${API}/v1/passportization/groups?${getQueryParams(params)}`
export const CREATE_OBJECTS_GROUP_TREE_ENDPOINT = ({ params }) => `${API}/v1/installation-tree/object-for-group?${getQueryParams(params)}`
export const OBJECTS_GROUPS_CREATE_ENDPOINT = `${API}/v2/installation/group`
export const OBJECTS_GROUP_CHILDREN_ENDPOINT = (id) => `${API}/v1/passportization/groups/${id}/elements`
export const OBJECTS_GROUPS_ENDPOINT = (id) => `${API}/v1/passportization/groups/${id}`

export const GET_SCHEDULE_DATA_URL = (id) => `${API}/v1/schedule-manager/schedules/groups/${id}`
export const GET_SCHEDULE_OPTIONS_URL = ({ params }) => `${API}/v1/schedule-manager/schedules/available?${getQueryParams(params)}`
export const SET_SCHEDULE_URL = `${API}/v1/schedule-manager/schedules/assign-group`
export const RESET_SCHEDULE_URL = ({ id, params }) => `${API}/v1/schedule-manager/schedules/${id}/unassign-group?${getQueryParams(params)}`

export const GET_VERIFICATION_OPTIONS_URL = (groupId) => `${API}/v1/group/verification/${groupId}/available-status`
export const SET_VERIFICATION_STATUS_URL = (groupId) =>`${API}/v1/group/verification/${groupId}`
export const VERIFICATION_ALL_URL = `${API}/v1/group/verification/change-status`


export const GET_CONTROL_DATA_URL = ({ params }) => `${API}/v1/control-data?${getQueryParams(params)}`
export const SET_CONTROL_DATA_URL = (id) => `${API}/v1/control-data/${id}`

export const GET_PASSPORTIZATION_TABLE_URL = ({ params }) => `${API}/v1/filter/group/installation?${getQueryParams(params)}`
export const SET_PASSPORTIZATION_DATA_URL = `${API}/v1/installation/group/field`
export const GET_PASSPORTIZATION_OPTIONS_URL = (id) => `${API}/v1/installation/group/${id}/field/available`
export const GET_PASSPORTIZATION_TABLE_FILE_URL = ({ params }) => `${API}/v1/report-file/xlsx/generator/group-passport?${getQueryParams(params)}`

// objectReference
export const GET_REALE_TABLE_URL = ({ params }) => `${API}/v1/filter/object-property/real?${getQueryParams(params)}`
export const GET_MOVEABLE_TABLE_URL = ({ params }) => `${API}/v1/filter/object-property/moveable?${getQueryParams(params)}`
export const GET_REALE_FILE_URL = ({ params }) => `${API}/v1/installation/object-property/generate/real?${getQueryParams(params)}`
export const GET_MOVEABLE_FILE_URL = ({ params }) => `${API}/v1/installation/object-property/generate/moveable?${getQueryParams(params)}`
export const GET_INSTALLATION_OBJECT_URL = ({ params, parentId }) => `${API}/v1/installation/${parentId}/object-property/installation-object?${getQueryParams(params)}`
export const GET_IMMOVEABLE_DICTINORY_URL = ({ params }) => `${API}/v1/object-property/dictionary/immovable?${getQueryParams(params)}`
export const GET_MOVEABLE_DICTINORY_URL = ({ params }) => `${API}/v1/object-property/dictionary/moveable?${getQueryParams(params)}`

// actionLog
export const GET_ACTION_LOG_TABLE_URL = ({ params }) => `${API}/v1/user-actions?${getQueryParams(params)}`
export const GET_ACTION_LOG_FILE_URL = ({ params }) => `${API}/v1/user-actions/report/generate?${getQueryParams(params)}`
export const GET_ACTION_LOG_DICTINORY_URL = ({ params }) => `${API}/v1/user-actions/dictionary?${getQueryParams(params)}`


// inventory
export const GET_INVENTORY_TABLE_URL = ({ params }) => `${API}/v1/filter/inventory?${getQueryParams(params)}`
export const GET_INVENTORY_TOTAL_URL = ({ params }) => `${API}/v1/inventory/total?${getQueryParams(params)}`





export const SCHEDULER_DASHBOARD_URL = `${API}/scheduler/dashboard/settings`